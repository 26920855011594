<template>
  <div
    class="floor-plan-card p-1 cursor-pointer"
    @click="toDetail(floorPlan.id)"
  >
    <div class="flat-card row flex-column full-height">
      <div class="w-100 position-relative">
        <img
          class="floor-plan-thumbnail cursor-pointer"
          :src="`${$getFileURL(floorPlan.thumbnail[0])}`"
          :alt="floorPlan.name"
          loading="lazy"
          @error="$setAltPhoto"
        />
        <div v-if="floorPlan.lotType" class="banner-text">
          <span>{{ floorPlan.lotType.name }}</span>
        </div>
      </div>
      <div class="floor-plan-info row align-content-between w-100 p-2">
        <div class="col-12">
          <p class="floor-plan-name cursor-pointer">
            {{ floorPlan.name }}
          </p>

          <!-- Bedroom Bathroom -->
          <div class="col-12 row bed-and-bath mb-1">
            <p class="mr-3">
              <i class="fas fa-bed mr-1"></i>
              {{ floorPlan.numberOfBedroom }}
              <span v-if="floorPlan.extraBedroom">
                + {{ floorPlan.extraBedroom }}
              </span>
            </p>
            <p>
              <i class="fas fa-bath mr-1"></i>
              {{ floorPlan.numberOfBathroom }}
              <span v-if="floorPlan.extraBathroom">
                + {{ floorPlan.extraBathroom }}
              </span>
            </p>
          </div>

          <!-- Price -->
          <div v-if="floorPlan.salePrice != 0">
            <p class="sale-price">
              RM{{ $numberWithCommas(parseInt(floorPlan.salePrice)) }}
            </p>
            <p class="sale-price-per-unit">
              RM{{ $numberWithCommas(floorPlan.salePricePerUnit) }}/{{
                floorPlan.areaUnitType
              }}
            </p>
          </div>
        </div>

        <div class="col-12 row h-fit">
          <div class="col-12 text-right my-2">
            <!-- Edit unit type -->
            <fd-button
              v-if="canEdit"
              class="flat mx-1"
              @click.stop="editFloorPlan(floorPlan.id)"
              v-tooltip="`Edit`"
            >
              <i class="fas fa-pen"></i>
            </fd-button>
            <!-- Delete unit type -->
            <fd-button
              v-if="canDelete"
              class="flat mx-1"
              @click.stop="deleteFloorPlan(floorPlan.id, floorPlan.name)"
              v-tooltip="`Delete`"
            >
              <i class="fas fa-trash"></i>
            </fd-button>
          </div>
        </div>
        <div v-if="$slots['action']" class="col-12 row h-fit">
          <slot name="action"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  mixins: [],
  props: {
    floorPlan: {
      type: Object,
      required: true
    },
    canEdit: {
      type: Boolean,
      default: false
    },
    canDelete: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    toDetail(id) {
      this.$emit("toDetail", id);
    },
    editFloorPlan(id) {
      this.$emit("edit", id);
    },
    async deleteFloorPlan(id, name) {
      let confirmDelete = await this.$confirm({
        type: "alert",
        message: `Are you sure you want to delete floor plan "${name}"? This action cannot be undone.`
      });

      if (confirmDelete) {
        this.$emit("delete", id);
      }
    }
  }
};
</script>

<style lang="scss">
.floor-plan-card {
  img.floor-plan-thumbnail {
    @include image(cover);
    height: 250px;
    @media #{$breakpoint-down-xs} {
      height: 150px;
    }
  }

  .banner-text {
    display: inline;
    font-size: 12px;
    position: absolute;
    left: 5px;
    bottom: 5px;
    span {
      border-radius: 4px;
      color: white;
      padding: 6px 12px;
      margin-right: 2px;
      display: inline-block;
      background: $color-secondary;
    }
  }

  .floor-plan-info {
    flex-grow: 1;
    .floor-plan-name {
      font-size: 18px;
      font-weight: bold;
      width: fit-content;
    }
    .sale-price {
      font-size: 18px;
    }
  }

  .flat-card {
    border: solid 1px #cccccc;
    border-radius: 5px;
    background: white;
    &:hover {
      transition: 0.3s;
      box-shadow: 0px 5px 10px #00000025;
    }
  }
}
</style>
